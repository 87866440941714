import pumptr from "../assets/images/pumptr.gif";
import ilondance from "../assets/images/ilondance.gif";
import pumpcoin from "../assets/images/pumpcoin.svg";
import q from "../assets/images/q.png";
import x from "../assets/images/x.png";
import a from "../assets/images/a.png";
import t from "../assets/images/t.png";


const Header = () => {
    return ( 
        <>
         <div id="wrapper">
            <div id="main">
                <div class="inner">
                    <section id="home-section">
                        <div id="container02" class="style5 container default">
                            <div class="wrapper">
                                <div class="inner header-flex" data-onvisible-trigger="1">
                                    <ul class="style1">
                                        <li>
                                            <a href="https://x.com/Nakamigos_Pump" class="button n01">      
                                                 <img src={x} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x5e4da99ba6e36e2e10fed5e8393802f2aaca55f3" target="_blank" class="button n01">      
                                                 <img src={q} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://etherscan.io/address/0xeeC29b7A1fcA98d0667585F4fFEfBA3DB5c04c5d" target="_blank" class="button n01">      
                                                 <img src={a} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://t.me/pump_erc20" target="_blank" class="button n01">      
                                                 <img src={t} alt="" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        </>
    );
}
 
export default Header;