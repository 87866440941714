import pumptr from "../assets/images/pumptr.gif";
import ilondance from "../assets/images/ilondance.gif";
import _12gr from "../assets/images/1_2gr.gif";
import pumpcoin from "../assets/images/pumpcoin.svg";
import copy from "../assets/images/copy.png";
import { useClipboard } from 'use-clipboard-copy';

const Nakamigos = () => {
    const clipboard = useClipboard({
        onSuccess() {
          console.log('Text was copied successfully!')
        },
        onError() {
          console.log('Failed to copy text!')
        }
      });

    return ( 
        <>
         <div id="wrapper">
            <div id="main">
                <div class="inner">
                    <section id="home-section">
                        <div id="container02" class="style5 container default">
                            <div class="wrapper">
                                <div class="inner" data-onvisible-trigger="1">
                                    <div class="banner">
                                        <img src={pumptr} alt="" />
                                        <img className="_12gr" src={_12gr} alt="" />
                                    </div>
                                    <div class="title">
                                        <img src={pumpcoin} alt="" />
                                    </div>
                                    <p id="text10" class="style2">ERC-20 crypto meme token with rights to get profit or loss</p>
                                    <ul id="buttons03" class="style1 d-flex buttons">
                                        <li>
                                            <img src={ilondance} alt="" />
                                        </li>
                                        <li>
                                            <a href="https://app.uniswap.org/tokens/ethereum/0xeeC29b7A1fcA98d0667585F4fFEfBA3DB5c04c5d" target="_blank" class="button n01">      
                                                <span class="label">BY NOW</span>
                                            </a>
                                        </li>
                                        <li>
                                            <img src={ilondance} alt="" />
                                        </li>
                                    </ul>
                                    <p class="style2">Smart Contract Address:</p>
                                    <div className="copylabel"><input type="text" ref={clipboard.target} value="0xeeC29b7A1fcA98d0667585F4fFEfBA3DB5c04c5d"></input><button className="copybutton" onClick={clipboard.copy}><img className="copyicon" src={copy} alt="" /></button></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        </>
    );
}
 
export default Nakamigos;