import Nakamigos from "./components/nakamigos";
import Commercial from "./components/commercial";
import Footer from "./components/footer";
import Header from "./components/header";

function App() {
  return (
    <>
        <main className="main">
            <Header/>
            <Nakamigos/>
            <Commercial/>
            <Footer/>
        </main>
    </>
  );
}

export default App;
