import cat from "../assets/images/cat.gif";
import footerinfo from "../assets/images/footerinfo.svg";
import q from "../assets/images/q.png";
import x from "../assets/images/x.png";
import a from "../assets/images/a.png";
import t from "../assets/images/t.png";
import tramp from "../assets/images/tramp.gif";
import _1gr from "../assets/images/2_1gr.gif";
import _2gr from "../assets/images/2gr.gif";

const Footer = () => {
    return ( 
        <>
         <div id="wrapper">
            <div id="main">
                <div class="inner fon">
                    <section id="home-section footer">
                        <div id="container07" class="style1 container default fon">
                            <div class="wrapper">
                                <div class="inner pump-is" data-onvisible-trigger="1">
                                    <p id="text14" class="style5">
                                        Even Trump invest in $Pump to make the fckn mooning Jump
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div id="image04" class="style1 image full screen" data-position="center">
                            <span class="frame frame-memtwo">
                                <img src={tramp} alt="" />
                                <div class="content" data-position="center">
                                    <h1>ROADMAP</h1>
                                    <ul>
                                        <li>Phase 1: Memes, Good vibes, Preparing for Takeoff</li>
                                        <li>Phase 2: Swap, Vibe, HODL, Repeat</li>
                                        <li>Phase 3: CEX Listings:  Pump Takeover, Staking</li>
                                        <li>Phase 4: Expanding Pump Ecosystem</li>
                                    </ul>
                                </div>
                            </span>
                        </div>
                        <div class="style3 footer-flex">
                                <div class="inner footer-header-flex" data-onvisible-trigger="1">
                                    <ul class="style1">
                                        <li>
                                            <a href="https://x.com/Nakamigos_Pump"  class="button n01">      
                                                 <img src={x} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x5e4da99ba6e36e2e10fed5e8393802f2aaca55f3" target="_blank" class="button n01">      
                                                 <img src={q} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://etherscan.io/address/0xeeC29b7A1fcA98d0667585F4fFEfBA3DB5c04c5d" target="_blank" class="button n01">      
                                                 <img src={a} alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://t.me/pump_erc20" target="_blank" class="button n01">      
                                                 <img src={t} alt="" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                        </div>
                            <p id="text16" class="style4">
                                $PUMP is a meme coin with no intrinsic value or expectation of financial return. The coin is completely useless and for entertainment purposes only.
                            </p>
                        <div id="container04" class="style1 container default">
                            <div class="wrapper">
                                <div class="inner" data-onvisible-trigger="1">
                                    <div class="footerinfo">
                                        <img src={footerinfo} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <img class="cat" src={cat} alt="" />
            <img class="_1gr" src={_1gr} alt="" />
            <img class="_2gr" src={_2gr} alt="" />
        </div>
        </>
    );
}
 
export default Footer;