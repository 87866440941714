import dolor from "../assets/images/dolor.svg";
import coin from "../assets/images/1coin1.gif";
import memtwo from "../assets/images/memtwo.svg";
import naruto_pepe from "../assets/images/naruto_pepe.gif";
import cat from "../assets/images/cat.gif";

const Commercial = () => {
    return ( 
        <>
         <div id="wrapper">
            <div id="main">
                <div class="inner">
                    <section id="home-section">
                        <div id="image02" class="style1 full screen" data-position="center">
                            <div class="wrap">
                                <div class="items-wrap">
                                    <div class="items marquee">
                                    <div class="item frame pumpit"><img src={dolor} alt="" /></div>
                                    <div class="item frame pumpit coin"><img src={coin} alt="" /></div>
                                    <div class="item frame pumpit"><img src={dolor} alt="" /></div>
                                    <div class="item frame pumpit coin"><img src={coin} alt="" /></div>
                                    <div class="item frame pumpit"><img src={dolor} alt="" /></div>
                                    <div class="item frame pumpit coin"><img src={coin} alt="" /></div>
                                    <div class="item frame pumpit"><img src={dolor} alt="" /></div>
                                    <div class="item frame pumpit coin"><img src={coin} alt="" /></div>
                                    <div class="item frame pumpit"><img src={dolor} alt="" /></div>
                                    <div class="item frame pumpit coin"><img src={coin} alt="" /></div>
                                    </div>
                                    <div aria-hidden="true" class="items marquee">
                                    <div class="item frame pumpit"><img src={dolor} alt="" /></div>
                                    <div class="item pumpit coin"><img src={coin} alt="" /></div>
                                    <div class="item frame pumpit"><img src={dolor} alt="" /></div>
                                    <div class="item frame pumpit coin"><img src={coin} alt="" /></div>
                                    <div class="item frame pumpit"><img src={dolor} alt="" /></div>
                                    <div class="item frame pumpit coin"><img src={coin} alt="" /></div>
                                    <div class="item frame pumpit"><img src={dolor} alt="" /></div>
                                    <div class="item frame pumpit coin"><img src={coin} alt="" /></div>
                                    <div class="item frame pumpit"><img src={dolor} alt="" /></div>
                                    <div class="item frame pumpit coin"><img src={coin} alt="" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="container07" class="style1 container default fon">
                            <div class="wrapper">
                                <div class="inner invest" data-onvisible-trigger="1">
                                    <p id="text14" class="style2">
                                        Don't be a chump - invest in $Pump
                                    </p>
                                    <img src={naruto_pepe} alt="" />
                                </div>
                            </div>
                        </div>
                        <div id="image04" class="style1 image full screen" data-position="center">
                            <span class="frame">
                                <img src={memtwo} alt="" />
                                <div class="content" data-position="center">
                                    <h1>TOKENOMICS</h1>
                                    <ul>
                                        <li>TOTAL SUPPLY</li>
                                        <li>50% TOKENS HAS BEEN BURNT</li>
                                        <li>No Taxes</li>
                                        <li>No Bullshit</li>
                                        <li>Just Pump</li>
                                        <li>LP Burnt</li>
                                        <li>Contract Ownership Renounced</li>
                                    </ul>
                                </div>
                                <div class="content" data-position="center">
                                    <a>
                                        <span class="label">1,000,000,000</span>
                                    </a>
                                </div>  
                            </span>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        </>
    );
}
 
export default Commercial;